import { createTimezoneCorrectedDate, dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { Shift } from '@/services/gql/graphql.generated';
import { format } from 'date-fns';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  shift: Shift;
}

export const EssVacantShift: FC<Props> = ({ shift }) => {
  let className = 'border-b px-5 py-4 flex justify-between';

  const today = dateToApiFormat(new Date());
  const shiftDate = dateToApiFormat(new Date(shift.date));

  const isPastDate = shiftDate < today;
  const isToday = shiftDate === today;

  if (isPastDate) {
    className = twMerge(className, 'bg-gray-100 text-calendar-inactive-default');
  } else if (isToday) {
    className = twMerge(className, 'bg-[#FCFCC9]');
  }

  return (
    <div className={className}>
      <div className="w-32 text-left">{format(createTimezoneCorrectedDate(new Date(shift.date)), 'dd/MM/yyyy')}</div>
      <div className="w-24">{`${secondsToTimeString(shift.start)}-${secondsToTimeString(shift.end)}`}</div>
      <div className="text-right">{shift.location?.name || '-'}</div>
    </div>
  );
};
