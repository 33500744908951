import { dateToCalendarStateIndex } from '@/helpers/calendarHelper';
import { dateToApiFormat, getDaysInThreeMonths } from '@/helpers/dateHelper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { useMapToLeaveTypeId } from '@/services/availability/useAvailabilityTypes';
import { AvailabilityException, WorkingPatternDay } from '@/services/gql/graphql.generated';
import { useCallback, useMemo } from 'react';
import { EmployeeSchedule, EmployeeScheduleDay } from './schedule.types';

export const useBuildSchedule = (selectedDate: Date) => {
  const mapToLeaveTypeId = useMapToLeaveTypeId();
  const dates = useMemo(() => getDaysInThreeMonths(selectedDate), [selectedDate]);

  return useCallback(
    (patterns: WorkingPatternDay[], exceptions: AvailabilityException[]) => {
      const schedule: EmployeeSchedule = {};

      dates.forEach(d => {
        const dayInSchedule: EmployeeScheduleDay = { date: d, availableTimes: [] };
        schedule[dateToCalendarStateIndex(d)] = dayInSchedule;

        const pattern = patterns?.find(p => p.dayNumber === d.getUTCDay());
        if (!pattern) {
          throw Error('Pattern list is incomplete.');
        }

        dayInSchedule.patternStart = pattern.startTime;
        dayInSchedule.patternEnd = pattern.endTime;

        if (!pattern.available) {
          dayInSchedule.leaveType = FixedLeaveTypeId.ROSTER;
        } else {
          const exceptionList = exceptions.filter(e => dateToApiFormat(new Date(e.date)) === dateToApiFormat(d));

          if (exceptionList.length === 0) {
            dayInSchedule.availableTimes.push({ start: pattern.startTime, end: pattern.endTime });
          } else {
            dayInSchedule.availableTimes = exceptionList.map(e => ({ start: e.start, end: e.end }));
            dayInSchedule.leaveType = mapToLeaveTypeId(exceptionList[0].leaveType?.id);
          }
        }
      });

      return schedule;
    },
    [mapToLeaveTypeId, dates]
  );
};
