import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { StaffDayTimes } from '@/services/availability/useStaffAvailability';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import { EmployeeScheduleDay } from '@/services/ess/schedule/schedule.types';
import { usePublicHoliday } from '@/services/holiday/usePublicHolidays';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { minutesToSeconds } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { TimeStepInput } from '../form/TimeStepInput';
import { EssLeaveTypePicker } from './EssLeaveTypePicker';

interface Props {
  scheduleDay: EmployeeScheduleDay;
  onChange?: (newDay: EmployeeScheduleDay) => void;
  maxTime: number,
  minTime: number
}

export const EssWorkPreferenceDay: FC<Props> = ({ scheduleDay, onChange, maxTime, minTime }) => {
  const defaultStart = scheduleDay.patternStart || 0;
  const defaultEnd = scheduleDay.patternEnd || 0;

  const buildAvailableTimes = useCallback(() => {
    const availableTimes: StaffDayTimes[] = [];

    if (scheduleDay.availableTimes.length > 0) {
      scheduleDay.availableTimes.forEach(pref => availableTimes.push({ start: pref.start, end: pref.end }));
    } else {
      availableTimes.push({ start: defaultStart, end: defaultEnd });
    }

    availableTimes.sort((a, b) => a.start - b.start);

    return availableTimes;
  }, [scheduleDay]);

  const [availableTimes, setAvailableTimes] = useState<StaffDayTimes[]>(buildAvailableTimes);

  useEffect(() => {
    setAvailableTimes(buildAvailableTimes());
  }, [buildAvailableTimes])

  const changeStartTime = (index: number) => (newTime: number) => {
    if (!onChange) {
      return;
    }

    const newTimes = [...availableTimes];

    const time = newTimes[index];

    time.start = newTime;
    // if the end time is smaller then the new start time, set to be the new time
    if (!time.end || time.end < newTime) {
      time.end = newTime;
    }

    onChange({ ...scheduleDay, availableTimes: newTimes });
  };

  const changeEndTime = (index: number) => (newTime: number) => {
    if (!onChange) {
      return;
    }

    const newTimes = [...availableTimes];

    const time = newTimes[index];

    if (!time.start || newTime >= time.start) {
      time.end = newTime;

      onChange({ ...scheduleDay, availableTimes: newTimes });
    }
  };

  const changeLeaveType = (newLeaveType?: string) => {
    if (!onChange) {
      return;
    }

    onChange({ ...scheduleDay, leaveType: newLeaveType, availableTimes });
  };

  const dateNumber = scheduleDay.date.getUTCDate();
  const dateLetter = strings.common.daysOfWeek[scheduleDay.date.getUTCDay()];

  const minuteIncrement = useSystemSettings(state => state.settings.minuteIncrement);

  const editable = onChange && scheduleDay.leaveType !== FixedLeaveTypeId.ROSTER;
  const timesEditable = editable && !scheduleDay.leaveType;

  const publicHoliday = usePublicHoliday(scheduleDay.date);

  const availabilityLimit = useSystemSettings(s => s.settings.maxTimePeriods);
  const canAdd = availableTimes.length < availabilityLimit;
  const canDelete = availableTimes.length > 1;

  const { registerConfirmation } = useConfirm(state => ({ registerConfirmation: state.registerForConfirmation }));

  const onRegisterConfirmation = () => {
    registerConfirmation(strings.ess.availability.saveConfirm);
  };

  const onDelete = (index: number) => () => {
    if (!onChange) {
      return;
    }

    const newTimes = [...availableTimes];
    newTimes.splice(index, 1);

    setAvailableTimes(newTimes);
    onChange({ ...scheduleDay, availableTimes: newTimes });
    onRegisterConfirmation();
  };

  const onAdd = () => {
    if (!onChange) {
      return;
    }

    const newTimes = [...availableTimes];
    newTimes.push({ start: defaultStart, end: defaultEnd });

    setAvailableTimes(newTimes);
    onChange({ ...scheduleDay, availableTimes: newTimes });
    onRegisterConfirmation();
  };

  return (
    <div className={`p-5 flex flex-row gap-5 items-center ${!editable ? 'bg-background-mid' : ''}`}>
      <div
        className={`w-16 flex items-center gap-2 text-md font-semibold font-calendar ${
          scheduleDay.date.getUTCDay() === 0 || !!publicHoliday
            ? 'text-calendar-active-sunday'
            : scheduleDay.date.getUTCDay() === 6
            ? 'text-calendar-active-saturday'
            : 'text-calendar-active-default'
        }`}
      >
        <span className="">{dateNumber}</span>
        <span className="">{dateLetter}</span>
      </div>
      <div className="flex-1">
        {publicHoliday && <div>{publicHoliday.name}</div>}
        <div className="flex items-center gap-2">
          <EssLeaveTypePicker leaveTypeId={scheduleDay.leaveType} onChange={changeLeaveType} className="flex-[4]" />
          <div className="space-y-2">
            {availableTimes.map((times, index) => (
              <div key={`${index}-${times.start}`} className="flex flex-row gap-2 items-center w-max">
                <TimeStepInput
                  className={`w-28 ${!editable ? 'bg-background-mid' : ''}`}
                  value={times.start || 0}
                  setValue={changeStartTime(index)}
                  incrementSeconds={minutesToSeconds(minuteIncrement)}
                  maxTime={maxTime}
                  minTime={minTime}
                  readonly={!timesEditable}
                />
                <span>-</span>
                <TimeStepInput
                  className={`w-28 ${!editable ? 'bg-background-mid' : ''}`}
                  value={times.end || 0}
                  setValue={changeEndTime(index)}
                  incrementSeconds={minutesToSeconds(minuteIncrement)}
                  maxTime={maxTime}
                  minTime={minTime}
                  readonly={!timesEditable}
                />
                {canDelete && (
                  <button className="text-red-500" onClick={onDelete(index)}>
                    <FiX />
                  </button>
                )}
                {canAdd && index === availableTimes.length - 1 && (
                  <button onClick={onAdd}>
                    <FiPlus />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
