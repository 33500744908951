import { dateToApiFormat } from '@/helpers/dateHelper';
import { AvailabilityError, AvailabilityErrorLevel } from '@/pages/ess/EssAvailabilityError';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { hoursToSeconds } from 'date-fns';
import { useMemo } from 'react';
import { EmployeeSchedule } from '../schedule/schedule.types';

export const useValidateScheduleAvailabilityDuration = (schedule: EmployeeSchedule, startDate: Date, endDate: Date) => {
  const minShiftDuration = useSystemSettings(state => state.settings.minShiftDuration);

  return useMemo(() => {
    const errors: AvailabilityError[] = [];

    const keys = Object.keys(schedule);

    keys.forEach(key => {
      const date = new Date(key);

      if (dateToApiFormat(date) < dateToApiFormat(startDate) || dateToApiFormat(date) > dateToApiFormat(endDate)) {
        return;
      }

      const scheduleDay = schedule[key];

      const dateNumber = scheduleDay.date.getUTCDate();
      const dateLetter = strings.common.daysOfWeek[scheduleDay.date.getUTCDay()];

      scheduleDay.availableTimes.forEach(time => {
        const start = time.start || 0;
        const end = time.end || hoursToSeconds(24);
        const duration = end - start;
        if (duration < Number(minShiftDuration)) {
          errors.push({
            text: strings.ess.availability.warningModal.availabilityBelowMinDuration(
              `${dateNumber} ${dateLetter}`,
              duration / 3600,
              minShiftDuration / 3600
            ),
            level: AvailabilityErrorLevel.ERROR
          });
        }
      });
    });

    return errors;
  }, [endDate, minShiftDuration, schedule, startDate]);
};
