import gql from "graphql-tag";
import { useCallback } from "react";
import { useSubscription } from "urql";

const NOTIFICATION_CHANGE_SUBSCRIPTION = gql`
    subscription NotificationChangeSubscription {
        notificationChange {
            locationId
            notificationId
            date
        }
    }
`;

export const useNotificationChangeMonitor = (locationId: number, startDate: Date, callback: ()=>void) => {
  const handleSubscription = useCallback((previous: any, response: any) => {
    if (!response.notificationChange) return;
    const date = new Date(response.notificationChange.date);
    date.setUTCHours(0, 0, 0, 0);
    if (date >= startDate && locationId === response.notificationChange.locationId) {
      callback();
    }
  }, [locationId, startDate, callback]);

  useSubscription({
      query: NOTIFICATION_CHANGE_SUBSCRIPTION
    },
    handleSubscription);
};