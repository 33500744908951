import { dateToApiFormat, secondsToTimeString } from '@/helpers/dateHelper';
import { AvailabilityError, AvailabilityErrorLevel } from '@/pages/ess/EssAvailabilityError';
import { strings } from '@/services/translation/strings';
import { hoursToSeconds } from 'date-fns';
import { useMemo } from 'react';
import { EmployeeSchedule } from '../schedule/schedule.types';

export const useValidateScheduleOutsidePattern = (schedule: EmployeeSchedule, startDate: Date, endDate: Date) => {
  return useMemo(() => {
    const errors: AvailabilityError[] = [];

    const keys = Object.keys(schedule);

    keys.forEach(key => {
      const date = new Date(key);

      if (dateToApiFormat(date) < dateToApiFormat(startDate) || dateToApiFormat(date) > dateToApiFormat(endDate)) {
        return;
      }

      const scheduleDay = schedule[key];

      const dateNumber = scheduleDay.date.getUTCDate();
      const dateLetter = strings.common.daysOfWeek[scheduleDay.date.getUTCDay()];

      let startError = false;
      let endError = false;

      scheduleDay.availableTimes.forEach(time => {
        const start = time.start || 0;
        const end = time.end || hoursToSeconds(24);
        
        if (scheduleDay.patternStart && start < scheduleDay.patternStart) {
          startError = true;
        }

        if (scheduleDay.patternEnd && end > scheduleDay.patternEnd) {
          endError = true;
        }
      });

      if (startError || endError) {
        errors.push({
          text: strings.ess.availability.warningModal.availabilityOutsidePattern(
            `${dateNumber} ${dateLetter}`,
            scheduleDay.patternStart ? secondsToTimeString(scheduleDay.patternStart) : '?',
            scheduleDay.patternEnd ? secondsToTimeString(scheduleDay.patternEnd) : '?'
          ),
          level: AvailabilityErrorLevel.WARNING
        });
      }
    });

    return errors;
  }, [endDate, schedule, startDate]);
};
